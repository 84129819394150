<template>
    <div class="flex">
        <div class="flex flex-center">
            <v-spacer></v-spacer>
            <v-btn large plain to="/home" title="Customer Support">Customer Support</v-btn>
            <v-btn large plain @click="renderGolkondaCrm()" title="CRM">CRM</v-btn>
            <v-btn large plain href="/docs" title="Knowledgebase">Knowledgebase</v-btn>
            <v-btn large plain @click="renderGolkondaMine()" title="Revenue">Revenue</v-btn>
            <v-spacer></v-spacer>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        renderGolkondaCrm() {
            this.$router.push("/golkonda/crm");
        },
        renderGolkondaMine() {
            this.$router.push("/golkonda/mine");
        },
    }
}
</script>


<style>
html {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.flex-center {
    display: flex;
    align-items: center;
}
</style>